import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";


export default function Reinvention() {
  return (
    <Layout pageTitle="Road to Reinvention">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Road to Reinvention - USI Projects</title>
        <meta name="description" content="During the first national lockdown in March, we conducted a survey from across Northern Ireland to understand how people's perceptions and behaviours had changed."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

       <section class="text-gray-700 body-font heading-container-reinvention">

       <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Road To Reinvention</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Urban Scale Interventions</p>
              </div>
          </section>
        </div>
      </section>





      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-primaryteal">Overview</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl   ">
                  In March 2020 the world was turned upside down by the Coronavirus pandemic and subsequent
                  restrictions. During the first national lockdown in March, we conducted a survey from across Northern
                  Ireland to understand how people's perceptions and behaviours had changed.<br/><br/>

                  The results from the survey were presented through an interactive web platform with key insights
                  around community spirit, localism, the environment, mental health and wellbeing, and cultural
                  activity.<br/><br/>

                  This led to the creation of a toolkit to Build Back Better; including themes on cultural reinvention,
                  hyper-localism, acting on climate, reframing social issues and focusing on wellbeing. A number of
                  tools were also created for cities and organisations to help in the recovery process.
                </p>
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase  ">Fields of
                  Practice</p>
                <p
                  class="my-4 text-gray-500 leading-relaxed    mt-0">Reinvention<br/>
                  Regeneration<br/>COVID-19 
                </p>
                <p class="text-2xl font-bold uppercase    mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed    mt-0">Thought Leadership<br/>
                  Citizen Engagement<br/>Toolkit Development</p>
                <p class="text-2xl font-bold uppercase     mt-8">Visit</p>
                <a className='text-sm' href="https://www.urbanscalereinvention.com/">
                  <p class="my-4 text-gray-500 leading-relaxed    mt-0">
                    urbanscalereinvention.com
                  </p>
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>


      <div class="md:mt-16 md:mr-16 md:ml-16 md:pt-16 md:pr-16 md:pl-16">
        <img src="../img/projects/mockup.png" alt=""/>
      </div>






      <section class="text-gray-700 body-font bg-teal-500">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <div class="flex flex-wrap">
            <div class="w-full md:px-40 xl:w-2/4 py-10 xl:px-32 mb-4 md:mb-0">

              <img data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true"
                src="https://ik.imagekit.io/usi/project-pages/12_-_neighbourhood_1_tUhs04IQgK7w.png"/>
            </div>
            <div data-scroll data-scroll-speed="1" class="w-full xl:w-2/4 py-4 sm:px-8 md:px-24 mb-4 md:mb-0">

              
              <p data-scroll data-scroll-speed="1"
                class="text-xl text-white md:text-2xl  p-6 md:pl-0 md:pr-0 md:pb-0md:mr-20">
                The toolkit is still valid and useful - being used across Belfast and Northern Ireland, and as far afield as
              </p>
              <img  data-scroll data-scroll-speed="1" class="mt-2" src="../img/projects/flags.png" alt=""/>
              <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading  md:mb-20  text-white">Copenhagen & Lisbon</h3>

              <img src="https://ik.imagekit.io/usi/project-pages/1_-_neighbourhood_1_klHCEwJKS.png" alt="foyle app"/>

            </div>
          </div>
        </div>
      </section>




      <img class="w-full" src="../img/projects/Isometric_H 1.png" alt=""/> 

      <img data-scroll data-scroll-offset="200" data-scroll-speed="2" class="w-full md:pl-20 md:pr-20 py-16"
      src="https://ik.imagekit.io/usi/project-pages/Isometric_2_k43eYvkAZ.png" alt=""></img>

<div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-blue-400">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../transport" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
